import {
  AccessCredentialAction,
  AccessRuleDetailFragment,
  CreateAccessCredentialsRuleDtoInput,
  PinCodeApplicability,
  UniquenessKind,
} from 'types/generated/graphql';

import { AdditionalRequirements, FormValues } from './schema';

export const getDefaultValuesFromDetail = (
  detail: AccessRuleDetailFragment
): Partial<FormValues> => {
  const primaryAdditionalRequirements = Object.values(
    AdditionalRequirements
  ).filter((value) => detail[value]);

  return {
    accessCredentialActions: detail.accessCredentialActions,
    primaryAdditionalRequirements: primaryAdditionalRequirements,
    pinCodeLength: detail.pinCodeLength || undefined,
    charactersRange: detail.charactersRange || undefined,
    uniqueCharacters: detail.uniqueCharacters || undefined,
    pinCodeApplicability: detail.pinCodeApplicability || undefined,
    requirePersonalIdentifier: detail.requirePersonalIdentifier,
    requirePhoneNumber: detail.requirePhoneNumber,
    requirePinCode: detail.requirePinCode,
    requireToSpecifyValidFrom: detail.requireToSpecifyValidFrom,
    requireToSpecifyValidTo: detail.requireToSpecifyValidTo,
    uniquenessKind: detail.uniquenessKind,
  };
};

export const formValuesToCreateOrUpdateAccessCredentialsRuleDtoInput = (
  values: FormValues,
  integrationProfileId: string,
  tenantSubjectId: string,
  projectId: string
): CreateAccessCredentialsRuleDtoInput => {
  return {
    accessCredentialActions:
      values.accessCredentialActions as AccessCredentialAction[],
    requireInstallationId: values.primaryAdditionalRequirements.includes(
      AdditionalRequirements.REQUIRE_INSTALLATION_ID
    ),
    requirePointId: values.primaryAdditionalRequirements.includes(
      AdditionalRequirements.REQUIRE_POINT_ID
    ),
    requirePersonalIdentifier: !!values.requirePersonalIdentifier,
    requirePhoneNumber: !!values.requirePhoneNumber,
    requirePinCode: !!values.requirePinCode,
    requireToSpecifyValidFrom: !!values.requireToSpecifyValidFrom,
    requireToSpecifyValidTo: !!values.requireToSpecifyValidTo,
    //Pin actions
    pinCodeLength: values.requirePinCode ? values.pinCodeLength : undefined,
    pinCodeApplicability: values.requirePinCode
      ? (values.pinCodeApplicability as PinCodeApplicability)
      : undefined,
    uniquenessKind: values.uniquenessKind as UniquenessKind,
    uniqueCharacters: values.uniqueCharacters,
    charactersRange: values.charactersRange,
    integrationProfileId,
    tenantSubjectId,
    projectId,
  };
};
