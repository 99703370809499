import { Box, Button, Flex, Heading, IconButton, Table } from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedSearchInput } from 'components/debounced-search-input/debounced-search-input';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { Link, TableWrapper, Td } from 'components/styled';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDto, useProjectsQuery } from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const columnHelper = createColumnHelper<ProjectDto>();

const Projects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const { subjectId } = useParams<{ subjectId?: string }>();

  const { data, isLoading, error } = useProjectsQuery({
    subjectIds: subjectId ?? '',
  });

  const tableData = data?.projects ?? [];

  const filteredData = tableData.filter((item) =>
    item?.name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  const navigateToProjectDetail = useCallback(
    (projectId: string) =>
      navigate(
        substituteRouteParams(RouteUrls.ProjectDetail, {
          subjectId,
          projectId,
        })
      ),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => {
          return (
            <Link
              to={substituteRouteParams(RouteUrls.ProjectDetail, {
                subjectId,
                projectId: row.id,
              })}
            >
              {row.name}
            </Link>
          );
        },
        {
          id: 'name',
          cell: (info) => info.getValue(),
          header: () => <Td>{t('Projects.Table.Name')}</Td>,
          enableSorting: false,
        }
      ),
      columnHelper.accessor((row) => row.ownerCompanyName, {
        id: 'ownerCompanyName',
        cell: (info) => info.getValue(),
        header: () => <Td>{t('Projects.Table.OwnerCompanyName')}</Td>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.providerCompanyName, {
        id: 'providerCompanyName',
        cell: (info) => info.getValue(),
        header: () => <Td>{t('Projects.Table.ProviderCompanyName')}</Td>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.integrationProfiles.length, {
        id: 'integrationProfiles',
        cell: (info) => info.getValue(),
        header: () => <Td>{t('Projects.Table.IntegrationProfiles')}</Td>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        enableSorting: false,
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <IconButton
              testId="projectDetail"
              name="chevronRight"
              onClick={() => navigateToProjectDetail(info.getValue())}
            />
          </Flex>
        ),
        header: () => <></>,
      }),
    ],
    [subjectId, navigate, t]
  );

  const table = useReactTable({
    data: filteredData as ProjectDto[],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <Flex flexDirection="column" gap="4rem">
      <Heading title={t('Projects.Header')} variant="h1" />
      <TableWrapper flexDirection="column">
        <Flex justifyContent="space-between" mb="10rem">
          <Box width="76rem">
            <DebouncedSearchInput
              label={t('Projects.Table.Search')}
              onChange={setSearchTerm}
              testId="projectsTableSearch"
            />
          </Box>
          <PolicyGuardWrapper resource="organization" action="createProject">
            <Button
              variant="primary"
              title={`+ ${t('Projects.Button.AddProject')}`}
              onClick={() =>
                navigate(
                  substituteRouteParams(RouteUrls.CreateProject, {
                    subjectId,
                  })
                )
              }
              testId="create-project-btn"
            />
          </PolicyGuardWrapper>
        </Flex>
        <Table
          onRowClick={(row) => navigateToProjectDetail(row.id)}
          table={table}
          isLoading={isLoading}
          isEmpty={data?.projects?.length === 0}
          errorInfo={{
            error,
            shortDescription: {
              title: t('Table.ShortDescriptionErrorTitle'),
              description: t('Projects.Error.Title'),
            },
            extendedDescription: {
              title: t('Projects.Error.Title'),
            },
          }}
          emptyState={{
            title: t('Projects.Table.EmptyState.Title'),
            description: t('Projects.Table.EmptyState.Description'),
          }}
        />
      </TableWrapper>
    </Flex>
  );
};

export default Projects;
