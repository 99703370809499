import { useQueryClient } from '@tanstack/react-query';
import { getQueryName } from 'utils/get-query-name/get-query-name';

/**
 * Returns a function that invalidates queries in the query cache based on the provided documents.
 * @returns A function that takes an array of document names and invalidates the corresponding queries in the query cache.
 */
export const useInvalidateQueries = () => {
  const queryCache = useQueryClient();

  const invalidate = (documents: string[]) => {
    documents.forEach((document) => {
      queryCache.invalidateQueries([getQueryName(document)]);
    });
  };
  return invalidate;
};
