import { Alert, Flex, Grid, InputTypes, Loading, Text } from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox } from 'components/inputs/checkbox';
import { FormInput } from 'components/inputs/input';
import { Select } from 'components/inputs/select';
import { Form } from 'components/styled';
import { INTEGRATION_PROFILE_MAX_RESERVATION_DAYS } from 'config';
import { IntegrationProfileFormInputs } from 'pages/integration-profile/components/integration-profile-form/schema';
import { ServiceTypeSelect } from 'pages/integration-profile/components/integration-profile-form/service-type-select';
import { useCompanyOptions } from 'pages/integration-profile/components/integration-profile-form/use-company-options';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'types/generated/graphql';

import { validationSchema } from './schema';

type IntegrationProfileFormProps = {
  projectId?: string;
  title: string;
  onSubmit: (data: IntegrationProfileFormInputs) => void;
  defaultValues?: Partial<IntegrationProfileFormInputs>;
  submitButtonLabel: string;
  onGoBack: () => void;
  hasNewProjectFields?: boolean;
  existingProfiles: Array<{
    tenantSubjectId: string;
    serviceType: ServiceType;
  }>;
  errorMessage?: string;
  displayActivateProfile?: boolean;
  isLoading?: boolean;
  isEdit?: boolean;
  isActivated?: boolean;
};

const IntegrationProfileForm = ({
  defaultValues,
  onSubmit,
  existingProfiles,
  errorMessage,
  isEdit,
  isActivated,
}: IntegrationProfileFormProps) => {
  const { t } = useTranslation();

  const formActions = useForm<IntegrationProfileFormInputs>({
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const selectedServiceType = formActions.watch('serviceType');
  const selectedTenantSubjectId = formActions.watch('tenantSubjectId');

  const existingRecord =
    !isEdit &&
    existingProfiles.find(
      (profile) =>
        profile.serviceType === selectedServiceType &&
        profile.tenantSubjectId === selectedTenantSubjectId
    );

  const { handleSubmit } = formActions;

  useEffect(() => {
    if (selectedServiceType !== ServiceType.HandToHand) {
      formActions.setValue('requireWhitelistVerification', false);
      formActions.setValue('verifySenderPhoneNumber', false);
    }
    if (
      selectedServiceType !== ServiceType.HandToHand &&
      selectedServiceType !== ServiceType.BagToBox
    ) {
      formActions.setValue('isPaymentFlowEnabled', false);
    }
  }, [selectedServiceType]);

  const {
    error,
    companiesOptions,
    isLoading: isLoadingCompanies,
  } = useCompanyOptions();

  if (isLoadingCompanies) {
    return <Loading />;
  }
  if (error)
    return <Alert type="error" message={t('Error.NotFound.Message')} />;

  return (
    <FormProvider {...formActions}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        {existingRecord && (
          <Alert
            message={t('IntegrationProfile.Form.ThisProfileTypeAlreadyExists')}
            type="error"
          />
        )}
        <Flex margin="0 auto" justifyContent="center" flexDirection="column">
          <Grid
            gridTemplateColumns="repeat(2, 1fr)"
            gridAutoRows="min-content"
            marginBottom="4rem"
            gridRowGap="6rem"
            gridColumnGap="7rem"
          >
            <Select
              isNullable={false}
              disabled={isEdit}
              name="tenantSubjectId"
              options={companiesOptions}
              label={t('IntegrationProfile.Form.TenantSubjectId')}
            />
            <ServiceTypeSelect isDisabled={isEdit} />
            <FormInput<IntegrationProfileFormInputs>
              label={t('IntegrationProfile.Form.WebHookUrl')}
              placeholder={t('IntegrationProfile.Form.WebHookUrl')}
              name="webHookUrl"
              disabled={isActivated}
            />
            <FormInput<IntegrationProfileFormInputs>
              label={t('IntegrationProfile.Form.PaymentUrl')}
              placeholder={t('IntegrationProfile.Form.PaymentUrl')}
              name="paymentUrl"
              disabled={isActivated}
            />
            <FormInput<IntegrationProfileFormInputs>
              label={t('IntegrationProfile.Form.PaymentAppName')}
              placeholder={t('IntegrationProfile.Form.PaymentAppName')}
              name="paymentAppName"
              disabled={isActivated}
            />

            <FormInput<IntegrationProfileFormInputs>
              label={t(
                'IntegrationProfile.Form.MaxUnanchoredBookingPercentageRatio'
              )}
              placeholder={t(
                'IntegrationProfile.Form.MaxUnanchoredBookingPercentageRatio'
              )}
              name="maxUnanchoredBookingPercentageRatio"
              disabled={isActivated}
              min={1}
              type={InputTypes.NUMBER}
            />
          </Grid>
          <Flex flexDirection="column" gap="2rem" marginBottom="6rem">
            <Text
              content={t('IntegrationProfile.Form.MaxPeriod')}
              variant="large"
            />
            <Flex gap="3rem">
              <FormInput<IntegrationProfileFormInputs>
                label={t('IntegrationProfile.Form.MaxPeriodDays')}
                placeholder={t('IntegrationProfile.Form.MaxPeriodDays')}
                name="maxPeriodDays"
                type={InputTypes.NUMBER}
                min={0}
                width="2rem"
                disabled={isActivated}
                max={INTEGRATION_PROFILE_MAX_RESERVATION_DAYS}
              />
              <FormInput<IntegrationProfileFormInputs>
                label={t('IntegrationProfile.Form.MaxPeriodHours')}
                placeholder={t('IntegrationProfile.Form.MaxPeriodHours')}
                name="maxPeriodHours"
                type={InputTypes.NUMBER}
                width="2rem"
                min={0}
                max={23}
                disabled={isActivated}
              />
              <FormInput<IntegrationProfileFormInputs>
                label={t('IntegrationProfile.Form.MaxPeriodMinutes')}
                placeholder={t('IntegrationProfile.Form.MaxPeriodMinutes')}
                name="maxPeriodMinutes"
                type={InputTypes.NUMBER}
                width="2rem"
                min={0}
                max={59}
                disabled={isActivated}
              />
            </Flex>
          </Flex>
          <Checkbox<IntegrationProfileFormInputs>
            disabled={isActivated}
            testId="checkbox-handlingCustomersNotifyingOnBehalfOfPartner"
            name="handlingCustomersNotifyingOnBehalfOfPartner"
            label={t(
              'IntegrationProfile.Form.HandlingCustomersNotifyingOnBehalfOfPartner'
            )}
          />
          {selectedServiceType === ServiceType.HandToHand && (
            <>
              <Checkbox<IntegrationProfileFormInputs>
                disabled={isActivated}
                testId="checkbox-requireWhitelistVerification"
                name="requireWhitelistVerification"
                label={t(
                  'IntegrationProfile.Form.RequireWhitelistVerification'
                )}
              />
              <Checkbox<IntegrationProfileFormInputs>
                disabled={isEdit}
                testId="checkbox-verifySenderPhoneNumber"
                name="verifySenderPhoneNumber"
                label={t('IntegrationProfile.Form.VerifySenderPhoneNumber')}
              />
            </>
          )}
          {(selectedServiceType === ServiceType.HandToHand ||
            selectedServiceType === ServiceType.BagToBox) && (
            <Checkbox<IntegrationProfileFormInputs>
              disabled={isEdit}
              testId="checkbox-isPaymentFlowEnabled"
              name="isPaymentFlowEnabled"
              label={t('IntegrationProfile.Form.IsPaymentFlowEnabled')}
            />
          )}
        </Flex>
      </Form>
    </FormProvider>
  );
};

export default IntegrationProfileForm;
