import { useUserData } from '@conteg/auth';
import {
  Alert,
  Breadcrumb,
  Breadcrumbs,
  Flex,
  Heading,
  Icon,
  IconButton,
  Loading,
  showAlertModalError,
  Table,
} from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ContentLoader } from 'components/content-loader/content-loader';
import { PageHeading } from 'components/page-heading/page-heading';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { Link, TableWrapper, Td } from 'components/styled';
import { CreateHandToHandServiceDevtool } from 'devtools/create-hand-to-hand-service-devtool/create-hand-to-hand-service-devtool';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IntegrationProfileDto,
  Language,
  ProjectDocument,
  useProjectQuery,
  useUpdateProjectMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { getEnvironmentMode } from 'utils/get-environment-mode/get-environment-mode';
import { getServiceTypeTranslationKey } from 'utils/get-service-type-translation-key';
import { substituteRouteParams } from 'utils/routing/helpers';
import { CompanyDetailTabs, RouteUrls } from 'utils/routing/router';

import ProjectForm, { ProjectFormInputs } from './components/project-form';

const columnHelper = createColumnHelper<IntegrationProfileDto>();

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { projectId, subjectId } = useParams<{
    projectId: string;
    subjectId: string;
  }>();

  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();
  const user = useUserData();

  const canEditProject =
    usePolicyEvaluate('canEditProject', 'organization') || user?.isProvider;

  const { mutate: updateProject } = useUpdateProjectMutation();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useProjectQuery(
    {
      id: projectId ?? '',
    },
    {
      enabled: !!projectId,
      select: (data) => data.project,
    }
  );

  const handleSubmit = (formData: ProjectFormInputs) => {
    const { name, companyContact, defaultLanguage, fallbackLanguage } =
      formData;

    updateProject(
      {
        project: {
          id: projectId ?? '',
          name,
          companyContact,
          defaultLanguage: defaultLanguage as Language,
          fallbackLanguage: fallbackLanguage as Language,
        },
      },
      {
        onSuccess: () => {
          showSuccess(t('ProjectUpdateForm.Form.Submit.Success'));
          invalidate([ProjectDocument]);
        },
        onError: (err) =>
          showAlertModalError(t('ProjectUpdateForm.Form.Submit.Error'), err),
      }
    );
  };

  const navigateToProfileDetail = useCallback(
    (integrationProfileId: string) => {
      navigate(
        substituteRouteParams(RouteUrls.IntegrationProfileDetail, {
          subjectId,
          projectId,
          integrationProfileId,
        })
      );
    },
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => {
          return (
            <Link
              to={substituteRouteParams(RouteUrls.IntegrationProfileDetail, {
                subjectId,
                projectId,
                integrationProfileId: row.id,
              })}
            >
              {row.name}
            </Link>
          );
        },
        {
          id: 'name',
          cell: (info) => info.getValue(),
          header: () => (
            <Td>{t('ProjectForm.Form.Table.IntegrationProfiles_Name')}</Td>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor((row) => row.serviceType, {
        id: 'serviceType',
        cell: (info) => t(getServiceTypeTranslationKey(info.getValue())),
        header: () => (
          <Td>{t('ProjectForm.Form.Table.IntegrationProfiles_ServiceType')}</Td>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.activatedAt, {
        id: 'isActive',
        cell: (info) =>
          info.getValue() ? <Icon name="checkCircle" /> : <Icon name="close" />,
        header: () => (
          <Td>{t('ProjectForm.Form.Table.IntegrationProfiles_IsActive')}</Td>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.id, {
        id: 'subjectId',
        enableSorting: false,
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <IconButton
              testId="pointDetail"
              name="chevronRight"
              onClick={() => navigateToProfileDetail(info.getValue())}
            />
          </Flex>
        ),
        header: () => <></>,
      }),
    ],
    [navigate, projectId, subjectId, t]
  );

  const table = useReactTable({
    data: project?.integrationProfiles ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  if (!projectId)
    return (
      <Alert
        type="error"
        message={t('Error.MissingUrlParam', { value: 'projectId' })}
      />
    );

  if (isProjectLoading) return <Loading />;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <Breadcrumbs
            items={[
              {
                canGoBack: true,
                label: t('Breadcrumb.Home'),
                url: RouteUrls.Companies,
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailCompany'),
                url: substituteRouteParams(
                  `${RouteUrls.CompanyDetail}${CompanyDetailTabs.Projects}`,
                  {
                    subjectId: subjectId ?? '',
                  }
                ),
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailProject'),
                url: substituteRouteParams(RouteUrls.ProjectDetail, {
                  projectId,
                  subjectId,
                }),
              },
            ]}
            navigate={(to: Breadcrumb) => navigate(to.url)}
          />
          {isProjectLoading ? (
            <ContentLoader />
          ) : (
            <PageHeading title={project?.name ?? t('DetailProject.Header')} />
          )}
          <ProjectForm
            defaultValues={{
              companyContact: project?.companyContact,
              name: project?.name,
              defaultLanguage: project?.defaultLanguage,
              fallbackLanguage: project?.fallbackLanguage,
            }}
            canEdit={canEditProject}
            title={t('ProjectUpdateForm.Form.Header')}
            onSubmit={handleSubmit}
            submitButtonLabel={t('ProjectUpdateForm.Form.SubmitButton')}
            onGoBack={() =>
              navigate(
                substituteRouteParams(RouteUrls.CompanyDetail, { subjectId })
              )
            }
          />
          <Flex flexDirection="column" gap="4rem">
            <Heading
              title={t('ProjectForm.IntegrationProfiles.Header')}
              variant="h2"
            />
            <TableWrapper flexDirection="column">
              <Flex justifyContent="flex-end" mb="10rem">
                {project && subjectId && !getEnvironmentMode.isProduction && (
                  <CreateHandToHandServiceDevtool
                    projectId={project.id}
                    tenantSubjectId={subjectId}
                    verifySenderPhoneNumber={true}
                    requireWhitelistVerification={true}
                    isPaymentFlowEnabled={false}
                  />
                )}
              </Flex>
              <Table
                onRowClick={({ id }) => navigateToProfileDetail(id)}
                table={table}
                isEmpty={project?.integrationProfiles?.length === 0}
                isLoading={isProjectLoading}
                errorInfo={{
                  error: projectError,
                  shortDescription: {
                    title: t('Table.ShortDescriptionErrorTitle'),
                    description: t(
                      'ProjectForm.IntegrationProfiles.Error.Description'
                    ),
                  },
                  extendedDescription: {
                    title: t(
                      'ProjectForm.IntegrationProfiles.Error.Description'
                    ),
                  },
                }}
                emptyState={{
                  title: t('Table.NoData'),
                  description: t(
                    'ProjectForm.IntegrationProfiles.EmptyStateDescription'
                  ),
                }}
              />
            </TableWrapper>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectDetail;
