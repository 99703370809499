import { Checkbox } from 'components/inputs/checkbox';
import { FormValues } from 'pages/access-rule-detail/schema';
import { FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AccessRulesCheckboxProps = {
  name: FieldPath<FormValues>;
  disabled?: boolean;
};

export const AccessRulesCheckbox = ({
  name,
  disabled,
}: AccessRulesCheckboxProps) => {
  const { t } = useTranslation();
  return (
    <Checkbox<FormValues>
      testId="checkbox-handlingCustomersNotifyingOnBehalfOfPartner"
      name={name}
      label={t(`IntegrationProfileAccessRules.${name}`)}
      disabled={disabled}
    />
  );
};
