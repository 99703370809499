import {
  Alert,
  Box,
  ErrorDetailInfo,
  Flex,
  Heading,
  Icon,
  Loading,
  Select,
  styled,
  Text,
  useTheme,
} from '@conteg/ui';
import { useQuery } from '@tanstack/react-query';
import { OPA_POLICIES_NAME } from 'config';
import { groupRulesByResource } from 'pages/roles-documentation/helper-functions';
import { TableRoles } from 'pages/roles-documentation/table-roles';
import { Rule } from 'pages/roles-documentation/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRolesQuery } from 'types/generated/graphql';
import env from 'utils/env/env';

export const RolesDocumentation = () => {
  const {
    data: roleNames,
    isLoading: isLoadingRoles,
    error: rolesError,
  } = useRolesQuery(
    {},
    {
      select: (data) =>
        data
          ? data.roles
              ?.filter((value) => !!value && !!value.name)
              .map((role) => role?.name as string)
          : [],
    }
  );

  const { t } = useTranslation();
  const [rolesFiltr, setRolesFiltr] = useState<string | null>(null);
  const theme = useTheme();

  const {
    data,
    isLoading: isLoadingJSONFile,
    error,
  } = useQuery<{
    rules: Array<Rule>;
  }>(['roles'], () =>
    fetch(`${env.VITE_POLICY_AGENT_URL}/${OPA_POLICIES_NAME}.data.json`).then(
      (response) => response.json()
    )
  );

  if (isLoadingRoles || isLoadingJSONFile) {
    return <Loading />;
  }

  if (rolesError) {
    return (
      <ErrorDetailInfo
        error={error}
        title={t('RolesDocumentation.RoleNamesError')}
      />
    );
  }

  if (error) {
    return (
      <ErrorDetailInfo
        error={error}
        title={t('RolesDocumentation.ErrorTitle')}
      />
    );
  }

  if (!roleNames || !roleNames.length) {
    return <Alert type="error" message={t('RolesDocumentation.NoRoles')} />;
  }

  const groupedRules = groupRulesByResource(data?.rules || []);

  return (
    <Flex flexDirection="column" gap="4rem">
      <Heading title={t('RolesDocumentation.Title')} variant="h1" />
      <TableWrapper>
        <Box width="70rem" marginBottom="8rem">
          <Select
            isNullable
            userSingleValue={rolesFiltr}
            onSingleValueChange={(value) => setRolesFiltr(value)}
            options={roleNames.map((role) => ({
              label: t(`RolesDocumentation.Role.${role}`),
              value: role,
            }))}
            label={t('RolesDocumentation.DisplayAvailableActionsForRole')}
          />
        </Box>
        <Flex marginBottom="4rem" alignItems="center" gap="4rem">
          <CheckboxContainer>
            <Icon name="checkCircle" color={theme.colors.additional.success} />
          </CheckboxContainer>
          <Text
            variant="highlight"
            content={t('RolesDocumentation.ProviderOnly')}
          />
        </Flex>
        <Flex flexDirection="column" gap="10rem">
          {groupedRules &&
            Object.entries(groupedRules).map(([resource, rules]) => (
              <TableRoles
                roleNames={roleNames}
                key={resource}
                resource={resource}
                rules={rules}
                rolesFiltr={rolesFiltr ? [rolesFiltr] : null}
              />
            ))}
        </Flex>
      </TableWrapper>
    </Flex>
  );
};

export const TableWrapper = styled(Flex)`
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4rem;
  flex-direction: column;
`;

export const CheckboxContainer = styled(Flex)`
  font-size: 4rem;
  background-color: ${({ theme }) => theme.providerColor};
  width: 21rem;
  height: 12rem;
  justify-content: center;
  align-items: center;
`;
