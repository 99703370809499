import { Alert, Flex, Loading } from '@conteg/ui';
import { ContentLoader } from 'components/content-loader/content-loader';
import { PageError } from 'components/page-error/page-error';
import { PageHeading } from 'components/page-heading/page-heading';
import { transformIntegrationProfileToDefaultFormValues } from 'pages/integration-profile/components/integration-profile-form/form-values-transform-functions';
import { useProjectIntegrationProfiles } from 'pages/integration-profile/create-integration-profile/use-project-integration-profiles';
import { AccessRuleList } from 'pages/integration-profile/integration-profile-detail/access-rules-list/access-rule-list';
import { IntegrationProfilDetailBreadcrumbs } from 'pages/integration-profile/integration-profile-detail/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntegrationProfileQuery } from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

import IntegrationProfileForm from '../components/integration-profile-form/integration-profile-form';

const IntegrationProfileDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectId, subjectId, integrationProfileId } = useParams<{
    projectId: string;
    subjectId: string;
    integrationProfileId: string;
  }>();

  const integrationProfiles = useProjectIntegrationProfiles(
    projectId,
    integrationProfileId
  );

  const { data, isLoading, error } = useIntegrationProfileQuery(
    {
      id: integrationProfileId ?? '',
    },
    {
      enabled: !!integrationProfileId,
    }
  );

  const integrationProfile = data?.integrationProfile;

  if (isLoading) return <Loading />;

  if (error) {
    return (
      <PageError message={t('IntegrationProfile.Error.Detail')} error={error} />
    );
  }

  if (!integrationProfile)
    return <Alert type="error" message={t('Error.NotFound.Message')} />;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <IntegrationProfilDetailBreadcrumbs />
          {isLoading ? (
            <ContentLoader />
          ) : (
            <PageHeading
              title={
                data?.integrationProfile.name ??
                t('IntegrationProfileDetail.Header')
              }
            />
          )}
          <IntegrationProfileForm
            isEdit
            isLoading={false}
            displayActivateProfile={!integrationProfile.activatedAt}
            existingProfiles={integrationProfiles}
            title={t('IntegrationProfile.Update.Header')}
            onSubmit={() => null}
            submitButtonLabel={t('IntegrationProfile.Update')}
            onGoBack={() =>
              navigate(
                substituteRouteParams(RouteUrls.ProjectDetail, {
                  subjectId,
                  projectId,
                })
              )
            }
            defaultValues={transformIntegrationProfileToDefaultFormValues(
              integrationProfile
            )}
            isActivated={!!integrationProfile.activatedAt}
          />
        </Flex>
        <AccessRuleList />
      </Flex>
    </Flex>
  );
};

export default IntegrationProfileDetail;
