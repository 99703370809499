import { Box, styled, Text, useTheme } from '@conteg/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type SectionErrorMessageProps = {
  errorMessage?: string;
  children: ReactNode;
};

export const SectionErrorMessage = ({
  errorMessage,
  children,
}: SectionErrorMessageProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      p="2rem"
      borderColor={errorMessage ? theme.colors.additional.error : 'transparent'}
      borderStyle="solid"
      borderWidth="1px"
      borderRadius="1rem"
    >
      {children}
      {errorMessage && <ErrorMessage content={t(errorMessage)} />}
    </Box>
  );
};

const ErrorMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.additional.error};
`;
