import { Breadcrumb, Breadcrumbs } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

type IntegrationProfileDetailBreadcrumbsProps = {
  nextLevelBreadcrumb?: Breadcrumb;
};

export const IntegrationProfilDetailBreadcrumbs = ({
  nextLevelBreadcrumb,
}: IntegrationProfileDetailBreadcrumbsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { projectId, subjectId, integrationProfileId } = useParams<{
    projectId: string;
    subjectId: string;
    integrationProfileId: string;
  }>();

  const items: Breadcrumb[] = [
    {
      canGoBack: true,
      label: t('Breadcrumb.Home'),
      url: RouteUrls.Companies,
    },
    {
      canGoBack: true,
      label: t('Breadcrumb.DetailCompany'),
      url: substituteRouteParams(RouteUrls.CompanyDetail, {
        subjectId,
      }),
    },
    {
      canGoBack: true,
      label: t('Breadcrumb.DetailProject'),
      url: substituteRouteParams(RouteUrls.ProjectDetail, {
        subjectId,
        projectId,
      }),
    },
    {
      canGoBack: true,
      label: t('Breadcrumb.DetailIntegrationProfile'),
      url: substituteRouteParams(RouteUrls.IntegrationProfileDetail, {
        subjectId,
        projectId,
        integrationProfileId,
      }),
    },
  ];

  if (nextLevelBreadcrumb) {
    items.push(nextLevelBreadcrumb);
  }

  return (
    <Breadcrumbs
      items={items}
      navigate={(to: Breadcrumb) => navigate(to.url)}
    />
  );
};
