import { Alert, Flex, Heading, InputTypes } from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { CheckboxSingleValues } from 'components/inputs/checkbox-single-values';
import { FormInput } from 'components/inputs/input';
import { MultiSelect } from 'components/inputs/multi-select';
import { Select } from 'components/inputs/select';
import { Form } from 'components/styled';
import { AccessRulesCheckbox } from 'pages/access-rule-detail/access-rules-checkbox';
import { SectionErrorMessage } from 'pages/access-rule-detail/section-error-message';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AccessCredentialAction,
  PinCodeApplicability,
  UniquenessKind,
} from 'types/generated/graphql';
import { createSelectEnumOptions } from 'utils/create-enum-select-options/create-enum-select-options';

import {
  AdditionalRequirements,
  FormValues,
  integrationProfileAccessRulesSchema,
} from './schema';

type IntegrationProfileAccessRulesFormProps = {
  onSubmit: (data: FormValues) => void;
  defaultValues?: Partial<FormValues>;
  errorMessage?: string;
  availableRules: AccessCredentialAction[];
  isLoading?: boolean;
  isEditMode?: boolean;
  isActivated?: boolean;
};

export const AccessRuleDetailForm = ({
  onSubmit,
  defaultValues,
  errorMessage,
  availableRules,
  isLoading,
  isActivated,
  isEditMode,
}: IntegrationProfileAccessRulesFormProps) => {
  const formActions = useForm<FormValues>({
    resolver: zodResolver(integrationProfileAccessRulesSchema),
    defaultValues,
  });

  const { t } = useTranslation();

  const customPinRules = formActions.watch('requirePinCode') === true;

  const errors = formActions.formState.errors;

  return (
    <FormProvider {...formActions}>
      <Form onSubmit={formActions.handleSubmit(onSubmit)}>
        <MultiSelect<FormValues>
          name="accessCredentialActions"
          options={availableRules.map((value) => ({
            value,
            label: t(`IntegrationProfileAccessRules.${value}`),
          }))}
          label={t('IntegrationProfileAccessRules.accessCredentialActions')}
          emptyStateText=""
          disabled={isEditMode}
        />
        <br />

        <Heading
          title={t('IntegrationProfileAccessRules.selectAtLeastOne')}
          variant="h3"
        />
        <SectionErrorMessage
          errorMessage={errors.secondaryAdditionalRequirements?.message}
        >
          <Flex gap="5rem" width="100%">
            <AccessRulesCheckbox
              name="requirePhoneNumber"
              disabled={isActivated}
            />
            <AccessRulesCheckbox name="requirePinCode" disabled={isActivated} />
            <AccessRulesCheckbox
              name="requirePersonalIdentifier"
              disabled={isActivated}
            />
          </Flex>
        </SectionErrorMessage>

        <MultiSelect<FormValues>
          name="primaryAdditionalRequirements"
          options={createSelectEnumOptions(
            AdditionalRequirements,
            'AdditionalRequirements',
            t
          )}
          label={t('IntegrationProfileAccessRules.AdditionalRequirements')}
          emptyStateText={t('Multiselect.NoMoreOptions')}
          disabled={isActivated}
        />

        {customPinRules && (
          <>
            <Heading
              title={t('IntegrationProfileAccessRules.pinActions')}
              variant="h3"
            />
            <Heading
              title={t(
                'IntegrationProfileAccessRules.exclusiveCharactersRangeTitle'
              )}
              variant="h4"
              bold="500"
            />
            <ul>
              {t(
                'IntegrationProfileAccessRules.exclusiveCharactersRangeDescription'
              )
                .split('\n')
                .map((text) => (
                  <li key={text}>{text}</li>
                ))}
            </ul>

            <Flex gap="5rem" width="100%">
              <FormInput
                name="uniqueCharacters"
                label={t('IntegrationProfileAccessRules.uniqueCharacters')}
                placeholder={t(
                  'IntegrationProfileAccessRules.uniqueCharacters'
                )}
                disabled={isActivated}
              />
              <FormInput
                name="charactersRange"
                label={t('IntegrationProfileAccessRules.charactersRange')}
                placeholder={t('IntegrationProfileAccessRules.charactersRange')}
                disabled={isActivated}
              />
              <FormInput
                name="pinCodeLength"
                label={t('IntegrationProfileAccessRules.pinCodeLength')}
                placeholder={t('IntegrationProfileAccessRules.pinCodeLength')}
                type={InputTypes.NUMBER}
                min={1}
                disabled={isActivated}
              />
            </Flex>
            <CheckboxSingleValues
              name="pinCodeApplicability"
              label={t('IntegrationProfileAccessRules.pinCodeApplicability')}
              options={[
                {
                  label: t(
                    `IntegrationProfileAccessRules.${PinCodeApplicability.Generate}`
                  ),
                  value: PinCodeApplicability.Generate,
                },
                {
                  label: t(
                    `IntegrationProfileAccessRules.${PinCodeApplicability.SpecifiedByPartnerInCreateUpdateRequests}`
                  ),
                  value:
                    PinCodeApplicability.SpecifiedByPartnerInCreateUpdateRequests,
                },
                {
                  label: t(
                    `IntegrationProfileAccessRules.${PinCodeApplicability.HandledByPartnerNeedToRequestPartnerFor}`
                  ),
                  value:
                    PinCodeApplicability.HandledByPartnerNeedToRequestPartnerFor,
                },
              ]}
              disabled={isActivated}
            />
          </>
        )}
        <Heading
          title={t('IntegrationProfileAccessRules.pinActions')}
          variant="h3"
        />
        <Flex gap="5rem">
          <AccessRulesCheckbox
            name="requireToSpecifyValidFrom"
            disabled={isActivated}
          />
          <AccessRulesCheckbox
            name="requireToSpecifyValidTo"
            disabled={isActivated}
          />
        </Flex>
        <Select
          disabled={isLoading || isActivated}
          isNullable={false}
          name="uniquenessKind"
          options={Object.values(UniquenessKind).map((item) => ({
            label: t(`IntegrationProfileAccessRules.${item}`),
            value: item,
          }))}
          label={t('IntegrationProfileAccessRules.uniquenessKind')}
        />
        {errorMessage && <Alert message={errorMessage} type="error" />}
      </Form>
    </FormProvider>
  );
};
