import { Option } from '@conteg/ui';

export const createSelectEnumOptions = (
  enumObject: object,
  baseTranslateKey: string,
  t: (key: string) => string
): Option[] => {
  return Object.values(enumObject).map((value) => ({
    label: t(`${baseTranslateKey}.${value}`),
    value,
  }));
};
