import { useProjectQuery } from 'types/generated/graphql';

export const useProjectIntegrationProfiles = (
  projectId: string | undefined,
  existingProfileId?: string
) => {
  const { data: project } = useProjectQuery(
    {
      id: projectId ?? '',
    },
    {
      enabled: !!projectId,
      select: (data) => data.project,
    }
  );

  return (
    project?.integrationProfiles
      ?.filter((item) => item.id !== existingProfileId)
      .map((item) => ({
        tenantSubjectId: item.tenantSubjectId,
        serviceType: item.serviceType,
      })) ?? []
  );
};
