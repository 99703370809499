import {
  Box,
  Button,
  Flex,
  showAlertModalError,
  styled,
  Switch,
  Text,
} from '@conteg/ui';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccessCredentialAction,
  PinCodeApplicability,
  ProjectDocument,
  ServiceType,
  UniquenessKind,
  useCreateAccessCredentialsRuleMutation,
  useCreateIntegrationProfileMutation,
} from 'types/generated/graphql';

type CreateHandToHandServiceDevtoolProps = {
  tenantSubjectId: string;
  projectId: string;
  verifySenderPhoneNumber: boolean;
  requireWhitelistVerification: boolean;
  isPaymentFlowEnabled: boolean;
};

export const CreateHandToHandServiceDevtool = ({
  tenantSubjectId,
  projectId,
}: CreateHandToHandServiceDevtoolProps) => {
  const [requireWhitelistVerification, setRequireWhitelistVerification] =
    useState(false);
  const [verifySenderPhoneNumber, setVerifySenderPhoneNumber] = useState(false);
  const [isPaymentFlowEnabled, setIsPaymentFlowEnabled] = useState(false);
  const [areDevtoolsActive, setAreDevtoolsActive] = useState(false);

  const { t } = useTranslation();
  const invalidate = useInvalidateQueries();

  const {
    mutateAsync: createIntegrationProfile,
    isLoading: isCreatingIntegrationProfile,
  } = useCreateIntegrationProfileMutation({
    onError: (error) =>
      showAlertModalError('Nepodařilo se vytvořit integrační profil', error),
  });

  const {
    mutateAsync: createAccessRuleAsync,
    isLoading: isCreatingAccessRule,
  } = useCreateAccessCredentialsRuleMutation({
    onError: (error) =>
      showAlertModalError('Nepodařilo se vytvořit pravidlo', error),
  });

  const handleSubmit = async () => {
    const integrationProfile = await createIntegrationProfile({
      input: {
        serviceType: ServiceType.HandToHand,
        maxPeriod: 'P4D',
        maxUnanchoredBookingPercentageRatio: 100,
        handlingCustomersNotifyingOnBehalfOfPartner: false,
        projectId,
        requireWhitelistVerification,
        tenantSubjectId,
        verifySenderPhoneNumber,
        isPaymentFlowEnabled,
      },
    });

    await createAccessRuleAsync({
      accessCredentialsRule: {
        accessCredentialActions: [AccessCredentialAction.Pickup],
        requirePointId: true,
        requireInstallationId: false,
        requirePhoneNumber: false,
        requirePinCode: true,
        requirePersonalIdentifier: false,
        requireToSpecifyValidFrom: false,
        requireToSpecifyValidTo: false,
        charactersRange: '0123456789',
        uniqueCharacters: '0123456789',
        pinCodeLength: 6,
        pinCodeApplicability: PinCodeApplicability.Generate,
        uniquenessKind: UniquenessKind.PinCode,
        integrationProfileId: '',
        tenantSubjectId,
      },
    });

    await createAccessRuleAsync({
      accessCredentialsRule: {
        accessCredentialActions: [AccessCredentialAction.Store],
        requirePointId: true,
        requireInstallationId: false,
        requirePhoneNumber: true,
        requirePinCode: false,
        requirePersonalIdentifier: false,
        requireToSpecifyValidFrom: false,
        requireToSpecifyValidTo: false,
        charactersRange: null,
        uniqueCharacters: null,
        pinCodeLength: null,
        pinCodeApplicability: null,
        uniquenessKind: UniquenessKind.PinCode,
        integrationProfileId: integrationProfile.createIntegrationProfile.id,
        tenantSubjectId,
      },
    });

    await createAccessRuleAsync({
      accessCredentialsRule: {
        accessCredentialActions: [
          AccessCredentialAction.Store,
          AccessCredentialAction.Pickup,
        ],
        requirePointId: true,
        requireInstallationId: false,
        requirePhoneNumber: true,
        requirePinCode: true,
        requirePersonalIdentifier: false,
        requireToSpecifyValidFrom: false,
        requireToSpecifyValidTo: false,
        pinCodeLength: 6,
        pinCodeApplicability: PinCodeApplicability.Generate,
        uniquenessKind: UniquenessKind.PinCode,
        integrationProfileId: integrationProfile.createIntegrationProfile.id,
        tenantSubjectId,
      },
    });

    invalidate([ProjectDocument]);
  };

  return (
    <Container hasWhiteBackground={areDevtoolsActive}>
      <Box
        style={
          areDevtoolsActive
            ? {}
            : {
                position: 'fixed',
                transform: 'rotate(-90deg)',
                top: '20rem',
                right: '-10rem',
              }
        }
      >
        <Button
          size="xs"
          title={areDevtoolsActive ? 'Schovat' : 'Zobrazit devtooly'}
          onClick={() => setAreDevtoolsActive((value) => !value)}
        />
      </Box>
      {areDevtoolsActive && (
        <>
          <Flex gap="3rem">
            <Text
              content={t('IntegrationProfile.Form.VerifySenderPhoneNumber')}
            />
            <Switch
              checked={verifySenderPhoneNumber}
              onChange={setVerifySenderPhoneNumber}
            />
          </Flex>
          <Flex gap="3rem">
            <Text
              content={t(
                'IntegrationProfile.Form.RequireWhitelistVerification'
              )}
            />
            <Switch
              checked={requireWhitelistVerification}
              onChange={setRequireWhitelistVerification}
            />
          </Flex>
          <Flex gap="3rem">
            <Text content={t('IntegrationProfile.Form.IsPaymentFlowEnabled')} />
            <Switch
              checked={isPaymentFlowEnabled}
              onChange={setIsPaymentFlowEnabled}
            />
          </Flex>
          <Button
            onClick={handleSubmit}
            title="Vytvořit hand to hand"
            disabled={isCreatingAccessRule || isCreatingIntegrationProfile}
          />
        </>
      )}
    </Container>
  );
};

export const Container = styled.div<{ hasWhiteBackground: boolean }>`
  position: fixed;
  top: 3rem;
  right: 3rem;
  z-index: 1000;
  padding: 3rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: ${({ hasWhiteBackground }) =>
    hasWhiteBackground ? 'white' : 'transparent'};
`;
