import {
  Alert,
  ErrorDetailInfo,
  Flex,
  Loading,
  showAlertModalError,
} from '@conteg/ui';
import { ContentLoader } from 'components/content-loader/content-loader';
import { PageHeading } from 'components/page-heading/page-heading';
import { TableWrapper } from 'components/styled';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { AccessRuleDetailForm } from 'pages/access-rule-detail/access-rule-detail-form';
import {
  formValuesToCreateOrUpdateAccessCredentialsRuleDtoInput,
  getDefaultValuesFromDetail,
} from 'pages/access-rule-detail/get-default-values-from-detail';
import { FormValues } from 'pages/access-rule-detail/schema';
import { IntegrationProfilDetailBreadcrumbs } from 'pages/integration-profile/integration-profile-detail/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AccessCredentialAction,
  AccessCredentialRuleListDocument,
  useAccessCredentialRuleDetailQuery,
  useUpdateAccessCredentialsRuleMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

export const AccessRuleDetail = () => {
  const { integrationProfileId, accessRuleId, subjectId, projectId } =
    useParams<{
      integrationProfileId: string;
      accessRuleId: string;
      subjectId: string;
      projectId: string;
    }>();

  const invalidate = useInvalidateQueries();
  const { t } = useTranslation();

  const {
    data: ruleDetailData,
    isLoading,
    error: ruleDetailError,
  } = useAccessCredentialRuleDetailQuery({
    integrationProfileId: integrationProfileId as string,
    ruleId: accessRuleId as string,
  });

  const { showSuccess } = useAlertContext();

  const { mutate, isLoading: isUpdatingProfileRule } =
    useUpdateAccessCredentialsRuleMutation({
      onSuccess: () => {
        invalidate([
          AccessCredentialRuleListDocument,
          AccessCredentialRuleListDocument,
        ]);
        showSuccess(
          t('IntegrationProfileAccessRules.AccessRuleDetail.SuccessMessage')
        );
      },
      onError: (err) => {
        showAlertModalError(
          t('IntegrationProfileAccessRules.AccessRuleDetail.ErrorMessage'),
          err
        );
      },
    });

  if (isLoading) {
    return <Loading />;
  }

  if (ruleDetailError) {
    return (
      <ErrorDetailInfo
        title={t(
          'IntegrationProfileAccessRules.AccessRuleDetail.Detail.ErrorMessage'
        )}
        error={ruleDetailError}
      />
    );
  }

  if (!ruleDetailData) {
    return <Alert message={t('AccessRuleDetail.MissingData')} type="error" />;
  }

  const handleSubmit = (values: FormValues) => {
    const parseValues = formValuesToCreateOrUpdateAccessCredentialsRuleDtoInput(
      values,
      integrationProfileId as string,
      subjectId as string,
      projectId as string
    );

    mutate({
      accessCredentialsRule: { ...parseValues, id: accessRuleId as string },
    });
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <IntegrationProfilDetailBreadcrumbs
            nextLevelBreadcrumb={{
              canGoBack: true,
              label: t('Breadcrumb.AccessRuleDetail'),
              url: substituteRouteParams(RouteUrls.AccessRuleDetail, {
                subjectId,
                projectId,
                integrationProfileId,
              }),
            }}
          />
          {isLoading ? (
            <ContentLoader />
          ) : (
            <PageHeading title={t('AccessRuleDetail.Title')} />
          )}
          {ruleDetailData.accessCredentialRuleDetail && (
            <TableWrapper>
              <AccessRuleDetailForm
                isLoading={isUpdatingProfileRule}
                availableRules={Object.values(AccessCredentialAction)}
                onSubmit={handleSubmit}
                isEditMode
                isActivated={!!ruleDetailData.integrationProfile.activatedAt}
                defaultValues={getDefaultValuesFromDetail(
                  ruleDetailData.accessCredentialRuleDetail
                )}
              />
            </TableWrapper>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
