import { ServiceType } from 'types/generated/graphql';

export const getServiceTypeTranslationKey = (serviceType: ServiceType) => {
  const resolver: Record<ServiceType, string> = {
    [ServiceType.BagToBox]: 'IntegrationProfileForm.ServiceType.BagToBox',
    [ServiceType.Delivery]: 'IntegrationProfileForm.ServiceType.Delivery',
    [ServiceType.HandToHand]: 'IntegrationProfileForm.ServiceType.HandToHand',
  };

  return resolver[serviceType];
};
