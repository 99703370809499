import { Duration } from 'luxon';
import { IntegrationProfileFormInputs } from 'pages/integration-profile/components/integration-profile-form/schema';
import { DefaultValues } from 'react-hook-form';
import {
  CreateIntegrationProfileDtoInput,
  IntegrationProfileFragment,
  ServiceType,
  UpdateIntegrationProfileDtoInput,
} from 'types/generated/graphql';
import { validateFormStringValue } from 'utils/validate-form-string-value/validate-form-string-value';

export const formValuesToCreateIntegrationProfileDtoInput = (
  projectId: string,
  {
    maxPeriodDays: day,
    maxPeriodHours: hour,
    maxPeriodMinutes: minutes,
    serviceType,
    handlingCustomersNotifyingOnBehalfOfPartner,
    ...formData
  }: IntegrationProfileFormInputs
): CreateIntegrationProfileDtoInput => ({
  webHookUrl: validateFormStringValue(formData.webHookUrl),
  paymentUrl: validateFormStringValue(formData.paymentUrl),
  paymentAppName: validateFormStringValue(formData.paymentAppName),
  tenantSubjectId: formData.tenantSubjectId,
  serviceType: serviceType as ServiceType,
  maxPeriod: Duration.fromObject({ day, hour, minutes }).toISO() || '',
  projectId: projectId,
  handlingCustomersNotifyingOnBehalfOfPartner:
    !!handlingCustomersNotifyingOnBehalfOfPartner,
  maxUnanchoredBookingPercentageRatio:
    formData.maxUnanchoredBookingPercentageRatio,
  requireWhitelistVerification: !!formData.requireWhitelistVerification,
  verifySenderPhoneNumber: !!formData.verifySenderPhoneNumber,
  isPaymentFlowEnabled: !!formData.isPaymentFlowEnabled,
});

export const formValuesToUpdateIntegrationProfileDtoInput = (
  id: string,
  {
    maxPeriodDays: day,
    maxPeriodHours: hour,
    maxPeriodMinutes: minutes,
    handlingCustomersNotifyingOnBehalfOfPartner,
    ...formData
  }: IntegrationProfileFormInputs
): UpdateIntegrationProfileDtoInput => ({
  id,
  webHookUrl: validateFormStringValue(formData.webHookUrl),
  paymentUrl: validateFormStringValue(formData.paymentUrl),
  paymentAppName: validateFormStringValue(formData.paymentAppName),
  maxPeriod: Duration.fromObject({ day, hour, minutes }).toISO() || '',
  handlingCustomersNotifyingOnBehalfOfPartner:
    !!handlingCustomersNotifyingOnBehalfOfPartner,
  maxUnanchoredBookingPercentageRatio:
    formData.maxUnanchoredBookingPercentageRatio,
  requireWhitelistVerification: !!formData.requireWhitelistVerification,
});

export const transformIntegrationProfileToDefaultFormValues = (
  integrationProfile: IntegrationProfileFragment
): DefaultValues<IntegrationProfileFormInputs> => {
  const maxPeriodDuration = Duration.fromISO(integrationProfile.maxPeriod);

  return {
    webHookUrl: integrationProfile.webHookUrl || undefined,
    handlingCustomersNotifyingOnBehalfOfPartner:
      !!integrationProfile.handlingCustomersNotifyingOnBehalfOfPartner,
    maxUnanchoredBookingPercentageRatio:
      integrationProfile.maxUnanchoredBookingPercentageRatio,
    paymentAppName: integrationProfile.paymentAppName || undefined,
    paymentUrl: integrationProfile.paymentUrl || undefined,
    serviceType: integrationProfile.serviceType,
    tenantSubjectId: integrationProfile.tenantSubjectId,
    maxPeriodDays: maxPeriodDuration.days,
    maxPeriodHours: maxPeriodDuration.hours,
    maxPeriodMinutes: maxPeriodDuration.minutes,
    requireWhitelistVerification:
      integrationProfile.requireWhitelistVerification,
    verifySenderPhoneNumber: integrationProfile.verifySenderPhoneNumber,
    isPaymentFlowEnabled: integrationProfile.isPaymentFlowEnabled,
  };
};
