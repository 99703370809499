import { ConfirmModal, showAlertModalError } from '@conteg/ui';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { useTranslation } from 'react-i18next';
import {
  useAddUserToRoleMutation,
  UserDocument,
  UserRoleFragment,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';

type AddUserRoleModalProps = {
  role?: UserRoleFragment | null;
  userId: string;
  onClose: () => void;
};
export const AddUserRoleModal = ({
  role,
  userId,
  onClose,
}: AddUserRoleModalProps) => {
  const { t } = useTranslation();
  const { showSuccess } = useAlertContext();
  const invalidate = useInvalidateQueries();

  const { mutate: addUserRole, isLoading } = useAddUserToRoleMutation({
    onSuccess: () => {
      showSuccess(t('UserUpdateForm.AddUserRole.SuccessMessage'));
      invalidate([UserDocument]);
      onClose();
    },
    onError: (err) => {
      showAlertModalError(t('UserUpdateForm.AddUserRole.ErrorMessage'), err);
    },
  });

  return (
    <ConfirmModal
      isLoading={isLoading}
      modalTitle={t('UserUpdateForm.AddUserRole.Title', {
        roleName: t(`RolesDocumentation.Role.${role?.name}`),
      })}
      isOpen={!!role}
      onClose={onClose}
      onConfirm={() => {
        if (role) {
          addUserRole({ roleId: role.id, userId });
        }
      }}
      cancelText={t('Cancel')}
      confirm={{
        variant: 'primary',
        text: t('Yes'),
      }}
    />
  );
};
