import {
  AccessCredentialAction,
  AccessRuleDetailFragment,
  useAccessCredentialRuleListQuery,
} from 'types/generated/graphql';
import { notEmpty } from 'utils/not-empty/not-empty';

export const useAccessCredentialsRulesList = (integrationProfileId: string) => {
  const { data, isLoading, error } = useAccessCredentialRuleListQuery({
    integrationProfileId,
  });

  const rules = data?.accessCredentialRuleList?.filter(notEmpty) || [];

  const usedActionsRules = getUsedActionRules(rules);

  return {
    isLoading,
    rules,
    error,
    usedActionsRules,
    unusedActionsRules: Object.values(AccessCredentialAction).filter(
      (item) => !usedActionsRules.includes(item)
    ),
  };
};

const getUsedActionRules = (rules: AccessRuleDetailFragment[]) => {
  if (!rules || rules.length === 0) {
    return [];
  }

  return rules.reduce(
    (
      accumulator: AccessCredentialAction[],
      currentValue: AccessRuleDetailFragment
    ) => [...accumulator, ...currentValue.accessCredentialActions],
    []
  );
};
