import { useMutation } from '@tanstack/react-query';

export const useUploadImage = () => {
  return useMutation<unknown, unknown, { url: string; file: File }>(
    ({ file, url }) =>
      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      })
  );
};
