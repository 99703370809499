import { Option } from '@conteg/ui';
import { useCompaniesQuery } from 'types/generated/graphql';

export const useCompanyOptions = () => {
  const { data, isLoading, error } = useCompaniesQuery(
    {
      pageIndex: 0,
      // TODO: This should be edited on GTW
      pageSize: 99,
    },
    {
      select: (data) => data.companies.items,
      staleTime: Infinity,
    }
  );
  const companiesOptions: Option[] =
    data?.map((company) => ({
      label: company.name,
      value: company.subjectId,
    })) ?? [];

  return {
    companiesOptions,
    isLoading,
    error,
  };
};
