import { Breadcrumb, Breadcrumbs, Flex, showAlertModalError } from '@conteg/ui';
import { PageHeading } from 'components/page-heading/page-heading';
import { isError } from 'lodash';
import { formValuesToCreateIntegrationProfileDtoInput } from 'pages/integration-profile/components/integration-profile-form/form-values-transform-functions';
import { IntegrationProfileFormInputs } from 'pages/integration-profile/components/integration-profile-form/schema';
import { useProjectIntegrationProfiles } from 'pages/integration-profile/create-integration-profile/use-project-integration-profiles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateIntegrationProfileMutation } from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

import IntegrationProfileForm from '../components/integration-profile-form/integration-profile-form';

const CreateIntegrationProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { subjectId, projectId } = useParams<{
    projectId: string;
    subjectId: string;
  }>();

  const integrationProfiles = useProjectIntegrationProfiles(projectId);

  const {
    mutateAsync: createIntegrationProfile,
    error,
    isLoading,
  } = useCreateIntegrationProfileMutation({
    onSuccess: () => {
      navigate(
        substituteRouteParams(RouteUrls.ProjectDetail, { subjectId, projectId })
      );
    },
  });

  const { showSuccess } = useAlertContext();

  const handleSubmit = async (formValues: IntegrationProfileFormInputs) => {
    await createIntegrationProfile(
      {
        input: formValuesToCreateIntegrationProfileDtoInput(
          projectId as string,
          formValues
        ),
      },
      {
        onSuccess: () =>
          showSuccess(t('IntegrationProfile.Form.Create.Success')),
        onError: (err) =>
          showAlertModalError(t('IntegrationProfile.Form.Create.Error'), err),
      }
    );
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <Breadcrumbs
            items={[
              {
                canGoBack: true,
                label: t('Breadcrumb.Home'),
                url: RouteUrls.Companies,
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailCompany'),
                url: substituteRouteParams(RouteUrls.CompanyDetail, {
                  subjectId,
                }),
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.DetailProject'),
                url: substituteRouteParams(RouteUrls.ProjectDetail, {
                  subjectId,
                  projectId,
                }),
              },
              {
                canGoBack: true,
                label: t('Breadcrumb.CreateIntegrationProfile'),
                url: substituteRouteParams(RouteUrls.CreateIntegrationProfile, {
                  subjectId,
                  projectId,
                }),
              },
            ]}
            navigate={(to: Breadcrumb) => navigate(to.url)}
          />
          <PageHeading title={t('IntegrationProfile.Create.Header')} />
          <IntegrationProfileForm
            existingProfiles={integrationProfiles}
            title={t('IntegrationProfile.Create.Header')}
            onSubmit={handleSubmit}
            submitButtonLabel={t(
              'IntegrationProfile.Button.AddIntegrationProfile'
            )}
            errorMessage={isError(error) ? error.message : undefined}
            isLoading={isLoading}
            onGoBack={() =>
              navigate(
                substituteRouteParams(RouteUrls.ProjectDetail, {
                  subjectId,
                  projectId,
                })
              )
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreateIntegrationProfile;
