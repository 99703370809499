import { Flex, Heading, showAlertModalError } from '@conteg/ui';
import { TableWrapper } from 'components/styled';
import { useInvalidateQueries } from 'hooks/use-invalidate-query';
import { AccessRuleDetailForm } from 'pages/access-rule-detail/access-rule-detail-form';
import { formValuesToCreateOrUpdateAccessCredentialsRuleDtoInput } from 'pages/access-rule-detail/get-default-values-from-detail';
import { FormValues } from 'pages/access-rule-detail/schema';
import { IntegrationProfilDetailBreadcrumbs } from 'pages/integration-profile/integration-profile-detail/breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AccessCredentialAction,
  AccessCredentialRuleListDocument,
  useCreateAccessCredentialsRuleMutation,
} from 'types/generated/graphql';
import { useAlertContext } from 'utils/alert-provider';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

export const NewAccessRule = () => {
  const { showSuccess } = useAlertContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const invalidate = useInvalidateQueries();

  const { integrationProfileId, subjectId, projectId } = useParams<{
    integrationProfileId: string;
    subjectId: string;
    projectId: string;
  }>();

  const { mutate, isLoading } = useCreateAccessCredentialsRuleMutation({
    onSuccess: () => {
      showSuccess(
        t('IntegrationProfileAccessRules.NewAccessRule.SuccessMessage')
      );
      invalidate([
        AccessCredentialRuleListDocument,
        AccessCredentialRuleListDocument,
      ]);
      navigate(
        substituteRouteParams(RouteUrls.IntegrationProfileDetail, {
          integrationProfileId,
          subjectId,
          projectId,
        })
      );
    },
    onError: (err) =>
      showAlertModalError(
        t('IntegrationProfileAccessRules.NewAccessRule.ErrorMessage'),
        err
      ),
  });

  const handleSubmit = async (formValues: FormValues) => {
    mutate({
      accessCredentialsRule:
        formValuesToCreateOrUpdateAccessCredentialsRuleDtoInput(
          formValues,
          integrationProfileId as string,
          subjectId as string,
          projectId as string
        ),
    });
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" gap="6.25rem">
        <Flex flexDirection="column" gap="9rem">
          <IntegrationProfilDetailBreadcrumbs
            nextLevelBreadcrumb={{
              canGoBack: true,
              label: t('Breadcrumb.AccessRuleDetail'),
              url: substituteRouteParams(RouteUrls.NewAccessRule, {
                subjectId,
                projectId,
                integrationProfileId,
              }),
            }}
          />
          <Heading
            title={t('IntegrationProfileAccessRules.NewAccessRule.Title')}
            variant="h1"
          />
          <TableWrapper>
            <AccessRuleDetailForm
              isLoading={isLoading}
              availableRules={Object.values(AccessCredentialAction)}
              onSubmit={handleSubmit}
            />
          </TableWrapper>
        </Flex>
      </Flex>
    </Flex>
  );
};
