import { FieldPath } from 'react-hook-form';
import { requiredSelect } from 'utils/validations';
import { z } from 'zod';

export const integrationProfileAccessRulesSchema = z
  .object({
    accessCredentialActions: z
      .array(z.string())
      .min(1, 'IntegrationProfileAccessRules.selectAtLeastOne'),
    pinCodeApplicability: z.string().optional(),
    primaryAdditionalRequirements: z.array(z.string()),
    secondaryAdditionalRequirements: z.string().optional(),
    uniqueCharacters: z.string().optional(),
    charactersRange: z.string().optional(),
    pinCodeLength: z.number().min(6).max(10).optional(),
    requirePhoneNumber: z.boolean().optional(),
    requirePinCode: z.boolean().optional(),
    requirePersonalIdentifier: z.boolean().optional(),
    requireToSpecifyValidFrom: z.boolean().optional(),
    requireToSpecifyValidTo: z.boolean().optional(),
    uniquenessKind: requiredSelect,
  })
  .superRefine((values, ctx) => {
    if (
      !values.requirePinCode &&
      !values.requirePersonalIdentifier &&
      !values.requirePhoneNumber
    ) {
      ctx.addIssue({
        path: ['secondaryAdditionalRequirements'],
        code: z.ZodIssueCode.custom,
        message: 'IntegrationProfileAccessRules.selectAtLeastOne',
      });
    }
  });

export type FormValues = z.infer<typeof integrationProfileAccessRulesSchema>;

export const checkboxFieldNames: Array<FieldPath<FormValues>> = [
  'requirePinCode',
  'requireToSpecifyValidFrom',
  'requireToSpecifyValidTo',
];

export enum AdditionalRequirements {
  REQUIRE_POINT_ID = 'requirePointId',
  REQUIRE_INSTALLATION_ID = 'requireInstallationId',
}
