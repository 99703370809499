import { Checkbox, Flex, Heading, Option } from '@conteg/ui';
import { InputHTMLAttributes } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

type SelectProps<T extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    name: FieldPath<T>;
    options: Option[];
    disabled?: boolean;
    label: string;
  };

export const CheckboxSingleValues = <T extends FieldValues>({
  name,
  options,
  label,
}: SelectProps<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Flex flexDirection="column" gap="3rem">
            <Heading title={label} variant="h3" />
            <Flex flexDirection="column">
              {options.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  onChange={() => field.onChange(option.value)}
                  value={field.value === option.value}
                  defaultValue={field.value === option.value}
                />
              ))}
            </Flex>
          </Flex>
        );
      }}
    />
  );
};
