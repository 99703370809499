import { Option, Select } from '@conteg/ui';
import { InputHTMLAttributes } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type MultiSelectProps<T extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    name: FieldPath<T>;
    options: Option[];
    label: string;
    disabled?: boolean;
    testId?: string;
    emptyStateText: string;
  };

export const MultiSelect = <T extends FieldValues>({
  name,
  options,
  label,
  testId,
  disabled,
  emptyStateText,
}: MultiSelectProps<T>) => {
  const { control } = useFormContext();

  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Select
          testId={testId}
          userMultiValue={value}
          isMultiSelect
          label={label}
          emptyStateText={emptyStateText}
          onMultiValueChange={onChange}
          options={options}
          width="100%"
          disabled={disabled}
          error={error?.message && t(error?.message)}
        />
      )}
    />
  );
};
