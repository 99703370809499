import { INTEGRATION_PROFILE_MAX_RESERVATION_DAYS } from 'config';
import _ from 'lodash';
import { requiredPositiveInteger, requiredString } from 'utils/validations';
import { z } from 'zod';

export const validationSchema = z
  .object({
    tenantSubjectId: requiredString,
    serviceType: requiredString,
    webHookUrl: z.union([
      z.literal(''),
      z.string().trim().url({ message: 'urlInvalidUrl' }),
    ]),
    requireWhitelistVerification: z.boolean().optional(),
    verifySenderPhoneNumber: z.boolean().optional(),
    isPaymentFlowEnabled: z.boolean().optional(),
    handlingCustomersNotifyingOnBehalfOfPartner: z.boolean().optional(),
    paymentUrl: z.union([
      z.literal(''),
      z.string().trim().url({ message: 'urlInvalidUrl' }),
    ]),
    paymentAppName: z.string().optional(),
    maxUnanchoredBookingPercentageRatio: requiredPositiveInteger,
    maxPeriodDays: z
      .number({ invalid_type_error: 'ThisIsNotValidNumber' })
      .min(0, 'ValueMustBeGreaterThenZero')
      .max(
        INTEGRATION_PROFILE_MAX_RESERVATION_DAYS,
        'IntegrationProfile.Form.MaxPeriodDays.MaxValueError'
      )
      .optional(),
    maxPeriodHours: z
      .number({ invalid_type_error: 'ThisIsNotValidNumber' })
      .min(0, 'ValueMustBeGreaterThenZero')
      .max(23, 'IntegrationProfile.Form.MaxPeriodHours.MaxValueError')
      .optional(),
    maxPeriodMinutes: z
      .number({ invalid_type_error: 'ThisIsNotValidNumber' })
      .min(0, 'ValueMustBeGreaterThenZero')
      .max(59, 'IntegrationProfile.Form.MaxPeriodMinutes.MaxValueError')
      .optional(),
  })
  .superRefine(
    (
      {
        webHookUrl,
        handlingCustomersNotifyingOnBehalfOfPartner,
        maxPeriodDays,
        maxPeriodHours,
        maxPeriodMinutes,
      },
      ctx
    ) => {
      if (
        (!webHookUrl || webHookUrl === '') &&
        handlingCustomersNotifyingOnBehalfOfPartner
      ) {
        ctx.addIssue({
          path: ['handlingCustomersNotifyingOnBehalfOfPartner'],
          code: z.ZodIssueCode.custom,
          message: 'IntegrationProfileAccessRules.NotAvailableForNoWebbHook',
        });
      }
      if (
        _.isNil(maxPeriodDays) &&
        _.isNil(maxPeriodHours) &&
        _.isNil(maxPeriodMinutes)
      ) {
        ctx.addIssue({
          path: ['maxPeriodDays'],
          code: z.ZodIssueCode.custom,
          message: 'IntegrationProfile.Form.MaxPeriod.Required',
        });
      }
    }
  );

export type IntegrationProfileFormInputs = z.infer<typeof validationSchema>;
