import { Flex, Heading, IconButton, Table } from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableWrapper } from 'components/styled';
import { useAccessCredentialsRulesList } from 'hooks/use-already-defined-credentials-actions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AccessRuleDetailFragment } from 'types/generated/graphql';
import { substituteRouteParams } from 'utils/routing/helpers';
import { RouteUrls } from 'utils/routing/router';

const columnHelper = createColumnHelper<AccessRuleDetailFragment>();

export const AccessRuleList = () => {
  const { projectId, subjectId, integrationProfileId } = useParams<{
    projectId: string;
    subjectId: string;
    integrationProfileId: string;
  }>();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { rules, isLoading, error } = useAccessCredentialsRulesList(
    integrationProfileId as string
  );

  const navigateToRuleDetail = (accessRuleId: string) => {
    navigate(
      substituteRouteParams(RouteUrls.AccessRuleDetail, {
        projectId,
        subjectId,
        integrationProfileId,
        accessRuleId,
      })
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => {
          return (
            <div>
              {row.accessCredentialActions
                .map((item) => t(`IntegrationProfileAccessRules.${item}`))
                .join(', ')}
            </div>
          );
        },
        {
          enableSorting: false,
          id: 'name',
          cell: (info) => info.getValue(),
          header: () =>
            t('IntegrationProfileAccessRules.accessCredentialActions'),
        }
      ),
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        enableSorting: false,
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <IconButton
              testId="ruleDetail"
              name="chevronRight"
              onClick={() => navigateToRuleDetail(info.getValue())}
            />
          </Flex>
        ),
        header: () => <></>,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data: rules,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  return (
    <TableWrapper flexDirection="column">
      <Flex p="2rem" justifyContent="space-between" width="100%">
        <Heading
          variant="h2"
          title={t('IntegrationProfileAccessRules.Title')}
        />
      </Flex>
      <Table
        table={table}
        onRowClick={(row: AccessRuleDetailFragment) =>
          navigateToRuleDetail(row.id)
        }
        isLoading={isLoading}
        isEmpty={rules.length === 0}
        emptyState={{
          description: t(
            'IntegrationProfileAccessRules.NewAccessRule.TableEmptyState.Description'
          ),
          title: t(
            'IntegrationProfileAccessRules.NewAccessRule.TableEmptyState.Title'
          ),
        }}
        errorInfo={{
          error,
          shortDescription: {
            title: t('Table.ShortDescriptionErrorTitle'),
            description: t('IntegrationProfileAccessRules.Error.Description'),
          },
          extendedDescription: {
            title: t('IntegrationProfileAccessRules.Error.Description'),
          },
        }}
      />
    </TableWrapper>
  );
};
