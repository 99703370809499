import { Select } from 'components/inputs/select';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'types/generated/graphql';
import { getServiceTypeTranslationKey } from 'utils/get-service-type-translation-key';

type ServiceTypeSelectProps = {
  isDisabled?: boolean;
};

export const ServiceTypeSelect = ({ isDisabled }: ServiceTypeSelectProps) => {
  const { t } = useTranslation();

  const options = [
    ServiceType.BagToBox,
    ServiceType.Delivery,
    ServiceType.HandToHand,
  ].map((value) => ({
    label: t(getServiceTypeTranslationKey(value)),
    value,
  }));

  return (
    <Select
      isNullable={false}
      disabled={isDisabled}
      name="serviceType"
      options={options}
      label={t('IntegrationProfile.Form.ServiceType')}
    />
  );
};
